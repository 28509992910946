import { useState, useContext } from "react";
import axios from "axios";
import { redirect,useLocation } from "react-router-dom";

import { UsersContext } from "../context/usersContext";

const useReq = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(null);

  const ctx = useContext(UsersContext);

  const startFetching = () => {
    setResponse(null);setUploadPercentage(null);
    setLoading(true);
    setError(null);
  };

  const clear = () => {
    setResponse(null);
    setError(null);
  };

  const fetchedData = () => {
    setLoading(false);
    setError("");
  };

  const requestData = (method, url, data, token) => {
    let config;
    if (!url || method === null || url === undefined) {
      return;
    }
    const link = `${process.env.REACT_APP_BACKEND_URL}${url}`;
    if (link === "http://localhost:8080/null") return;
    const tokendata = localStorage.getItem("token");
    if (tokendata) {
      config = {
        method,
        url: `${link}`,
        headers: {
          Authorization: `Bearer ${tokendata}`,
        },
        data,
        onUploadProgress: (progressEvent) => {
          console.log("11111111",progressEvent);
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setUploadPercentage(()=>percent);
        },
      };
    } else {
      config = {
        method,
        url: `${link}`,
        // url: `http://localhost:8080/api${url}`,
        data,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setUploadPercentage(percent);
        },
      };
    }

    startFetching();

    axios(config)
      .then((res) => {
        fetchedData();
        if (res.data.error) {
          const err = res.data.error;
          redirect(`/${err.split("/")[1]}`);
        } else if (res.data.redirect) {
          const { redirect } = res.data;
          redirect(`/${redirect.split("/")[1]}+/${redirect.split("/")[2]}`);
        } else {
          setResponse(res.data);
        }
      })
      .catch((err) => {
        fetchedData();
        if (err.response) {
          setErrorCode(err.response.status)
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            ctx.token = null;
            // window.location.reload(false);
            // window.location.href = "/";
            redirect("/signin");
            // history.push("/");
          } else if (err.response.status === 404) {
            setError(err.response.data);
            redirect("/projects");
            // window.location.href="/404"
          } else if (err.response.status === 403) {
            setError(err.response.data);
            redirect("/403");
          } else {
            setError(err.response.data);
            ctx &&
              typeof ctx.showAlert === "function" &&
              ctx.showAlert(err.response.data.message);
          }
        } else if (err.request) {
          // localStorage.removeItem("token");
          localStorage.removeItem("token");
          ctx.token = null;
          setError("Something went wrong. Try Again later.");
          redirect("/signin");
        } else {
          setError("Oops!! Unusual error occurred");
          ctx &&
            typeof ctx.showAlert === "function" &&
            ctx.showAlert("Oops!! Unusual error occurred");
        }
      });
  };

  return {
    loading,
    error,
    requestData,
    clear,
    response,
    setError,
    uploadPercentage,
    errorCode
  };
};

export default useReq;
