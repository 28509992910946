import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom'
const NonPrivateRoutes = () => {
  let auth = localStorage.getItem("token")
return (
    !auth ? <Suspense><Outlet/></Suspense> : <Navigate to='/home'/>
  )
}

export default NonPrivateRoutes;
