import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PageLoader from './Loader/PageLoader';
const FileViewer = () => {
    const location = useLocation();
    const [file,setFile] = useState()
    useEffect(()=>{
      if(new URLSearchParams(location.search).getAll('filepath')[0]){

        setFile([
          {
            uri: new URLSearchParams(location.search).getAll('filepath')[0],
            fileName:new URLSearchParams(location.search).getAll('filename')[0] || ""
          },
        ])
        
      }
    },[location]);

    const MyLoadingRenderer = ({ document, fileName }) => {
        const fileText = fileName || document?.fileType || "";
    
        if (fileText) {
          return <div>Loading ({fileText})...</div>;
        }
    
        return <div>Loading ...</div>;
      };


      
      if(!file){
        return <PageLoader/>
      }

  return (
    <DocViewer
    style={{height:"100vh",width:"100vw"}}
    documents={file}
    prefetchMethod="GET"
    pluginRenderers={DocViewerRenderers}
    config={{
      loadingRenderer: {
        overrideComponent: MyLoadingRenderer,
      },
    }}
    />
  )
}

export default FileViewer
