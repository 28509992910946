import React, { useEffect, useState } from "react";
import { IoNotifications } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import "./components/NotificationMenu.css";
import { Link, useNavigate } from "react-router-dom";
import useReq from "../../hook/useReq";
import LazyImage from "../../components/LazyImage";

function NotificationMenu() {
  const { response, error, loading, requestData } = useReq();
  const { response: singleReadResponse, error: singleReadError, loading: singleReadLoading, requestData: singleReadRequestData } = useReq();
  const { response: readResponse, error: readError, loading: readLoading, requestData: readRequestData } = useReq();
  const [notificationsData, setNotificationsData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    requestData("get", `/user/notifications?page=${page}`);
  }, [readResponse, singleReadResponse, page]);

  useEffect(() => {
    if (response !== null) {
      console.log("response:", response);
      setNotificationsData(response.notifications);
      setTotalPages(response.totalPages);
    }
  }, [response]);

  const handleNavigateToProject = (notification) => {
    if(notification.isRead === false) {
      singleReadRequestData("PATCH", `/user/notification/${notification._id}/read`);
    }

    navigate(`project/${notification.projectId}`);
    sessionStorage.setItem("nav", notification.convoId ? "com" : "doc");


  };

  const getTimeElapsed = (notification) => {
    const notificationDate = new Date(notification.notificationTime);
    const now = new Date();
    const timeDifference = now.getTime() - notificationDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else {
      const formattedTime = notificationDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      return `${days} days ago at ${formattedTime}`;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const groupedNotifications = notificationsData.reduce((acc, notification) => {
    const date = formatDate(notification.notificationTime);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(notification);
    return acc;
  }, {});

  const handleMarkAllAsRead = async () => {
    readRequestData("post", "/user/read-notifications");
  };

  const loadMoreNotifications = () => {
    if (page < totalPages) {
      setPage(page + 1); // Load next page
    }
  };

  // Filter unread notifications
  const unreadNotifications = notificationsData.filter(
    (notification) => !notification.isRead
  );

  return (
    <div className="mt-3">
      <button
        style={{ position: "relative" }}
        className="text-white p-1"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <IoNotifications size={24} />
        {unreadNotifications.length == 0 ? "" : 
        <div
        className="notification-count bg-light text-dark bolder"
        style={{
          position: "absolute",
          top: -5,
          right: -10,
          height: 18,
          width: 18,
          borderRadius: "50%",
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {unreadNotifications.length}
      </div>
        }
        
      </button>
      <div
        className="offcanvas offcanvas-end w-100 w-md-75 w-lg-500px"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="d-flex p-5" style={{ backgroundColor: "#f0f0f0" }}>
          <h5 id="offcanvasRightLabel" className="fs-2" style={{ color: "#264858", zIndex: 1 }}>
            <IoMdNotificationsOutline size={22} /> Notifications
            {unreadNotifications.length == 0 ? '' : 
            <span
            className="notification-count fs-5 text-light ms-2 rounded staticblue"
            style={{
              padding: "0px 7px",
            }}
          >
            {unreadNotifications.length}
          </span>
            }
            
          </h5>
          <div
            className="ms-auto cursor-pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{
              zIndex: 1,
            }}
          >
            <img src="/assets/icons/close-blue.png" alt="close" width={16} />
          </div>
        </div>

        <div className="overflow-scroll">
          <div className={`d-flex my-3 text-dark`}>
            <div
              className={`ms-auto me-4 ${unreadNotifications.length ? "cursor-pointer" : "cursor-default"
                }`}
            >
              <span className={`fs-5 ${unreadNotifications.length == 0 ? "text-muted " : 'text-black'}`} onClick={handleMarkAllAsRead}>
                <u>Mark all as read.</u>
              </span>
            </div>
          </div>

          {Object.keys(groupedNotifications).map((date,index) => (
            <div key={index} className={`px-5 ${index!=0 && "py-3"}`}>
              <h6 className="notification-date">{date}</h6>
              {groupedNotifications[date].map((notification,index) => (
                <div key={index}>
                  <div
                    className={`notification cursor-pointer p-2 ${notification.isRead ? "text-black" : "activeNotification"}`}
                    key={notification.id}
                    style={{ position: "relative" }}
                    onClick={() => handleNavigateToProject(notification)}
                    data-bs-dismiss="offcanvas"
  aria-label="Close"
                  >
                    <LazyImage
                      style={""}
                      src={
                        notification ? process.env.REACT_APP_SOCKETIO + notification.logo : ""
                      }
                    />
                    <div className="notification-content">
                      <div className="notification-header">
                        <div
                          className={`notification-type ${notification.isRead ? "text-black" : ""}`}
                        >
                          {notification.notificationTitle}
                        </div>
                        <div className="notification-time">
                          {getTimeElapsed(notification)}
                        </div>
                      </div>
                      <div className={`notification-description ${notification.isRead ? "text-black" : ""}`}>
                        {notification.notificationDesc}
                      </div>
                    </div>
                  </div>
                  <hr className="text-dark m-0" />
                </div>
              ))}
            </div>
          ))}


          {page < totalPages && (
            <div className="d-flex justify-content-center my-3">
              <button
                className="btn staticgreen"
                onClick={loadMoreNotifications}
                disabled={loading}
              >
                {loading ? "Loading..." : "Load More"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationMenu;
